import axios from 'axios';
import {getFilterByParamName} from '../pages/reports/newReportsPage/reportFilters/filterTypes/FilterUtils';

const instance = axios.create({baseURL: '/nakika-service/api'});
instance.interceptors.request.eject(
  instance.interceptorIds?.requestCasingInterceptor,
);

const reportTitleEnum = Object.freeze({
  lineVoidStats: 'Line Void Stats',
  qsrRefundStats: 'QSR Refund Stats',
  qsrTransactions: 'QSR_Transactions',
  discountStats: 'Discount Stats',
  EmpDiscountStats: 'Employee Discount Stats',
  voidedItems: 'Voided Items',
  deletedItems: 'Deleted Items',
  cancelledItems: 'Cancelled Items',
});

export const getReports = async (pillar, type) =>
  (await axios.get('/reports', {
    params: {
      limit: 200,
      pillar,
      type,
    },
  })).data;

export const getReportData = async (reportId) =>
  (await instance.get(`/reports/${reportId}`)).data;

export const getReportResults = async (reportId, params) =>
  (await instance.get(`/reports/${reportId}/results`, {
    params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  })).data;

export const getAsyncReportsHashId = async (params, reportId) => {
  const response = await instance.get(`/reports/${reportId}/hash_results`, {
    params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  return response.data.hashId;
};

export const getAsyncReportResults = async (hash, params) =>
  (await axios.get(`/reports/report/${hash}`, {
    params: {
      limit: params.limit,
      offset: params.offset,
      sorting_column: params.sort_by,
      sorting_order: params.sort_direction === 'ASCENDING' ? 'asc' : 'desc',
    },
  })).data;

export const deleteReportCache = async (hash) =>
  (await axios.delete(`/reports/report_cache/${hash}`)).data;

export const getReportsExportData = async (reportId, params) => {
  const url = `/reports/${reportId}/results.csv`;
  const results = await instance.get(url, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });
  return results.data;
};

export const getAsyncReportsExportData = async (reportId, params) => {
  const url = `/reports/${reportId}/hash_results.csv`;
  const results = await instance.get(url, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });

  return results.data;
};

export const removeReport = async (reportId) =>
  (await instance.delete(`/reports/${reportId}`)).data;

export const updateReport = async (reportId, report) =>
  (await instance.put(`/reports/${reportId}`, report)).data;

export const createReport = async (report) =>
  (await instance.post(`/reports`, report)).data;

export const duplicateReport = async (reportId, report) =>
  (await axios.post(`/reports/${reportId}/duplicate`, report)).data;

export const getRiskiestEmployees = async () =>
  (await axios.get('reports/riskiest_employees', {
    params: {
      limit: 5,
      sorting_column: 'employee_avg_risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

export const getRiskiestSites = async () =>
  (await axios.get('reports/riskiest_sites', {
    params: {
      limit: 5,
      sorting_column: 'site_avg_risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

export const getRiskiestTransactions = async () =>
  (await axios.get('reports/riskiest_transactions', {
    params: {
      limit: 5,
      lookback: 7,
      sorting_column: 'risk_weight',
      sorting_order: 'desc',
    },
  })).data?.results;

const extractWidgetApiParams = (params, pointEvent) => {
  const biStoreCodes = getFilterByParamName(params?.filters, 'sites')?.value;
  const offsetDays = getFilterByParamName(params?.filters, 'offsetDays')?.value;

  return {
    bi_site_id: pointEvent?.point?.siteId,
    report_state_id: pointEvent?.point?.nextStateId,
    calendar_day: params?.currentUserDate,
    look_back_days: params?.reportLookBackDays,
    // If store code is empty we will pass null
    bi_store_codes: biStoreCodes !== '' ? biStoreCodes : null,
    // If offset days is we will assume null and default to 7
    offset_days: offsetDays !== 7 ? offsetDays : null,
  };
};

export const getSiteByDayDataRefunds = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrRefundStats,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDataVoidedItems = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.voidedItems,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDataDeletedItems = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.deletedItems,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDataCancelledItems = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.cancelledItems,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDataRemoved = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.lineVoidStats,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDataSummary = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrTransactions,
      sorting_column: 'calendar_id',
      sorting_order: 'asc',
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayDiscountStats = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_by_day', {
    params: {
      report_display_name: reportTitleEnum.discountStats,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayRefundsManager = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_appr_mgr_by_day', {
    params: {
      report_display_name: reportTitleEnum.qsrRefundStats,
      ...apiParams,
    },
  })).data;
};

export const getSiteByDayEmpMgrDiscountStats = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);

  return (await axios.get('reports/sites_emp_disc_by_day', {
    params: {
      report_display_name: reportTitleEnum.EmpDiscountStats,
      ...apiParams,
    },
  })).data;
};

export const getSiteHierarchyData = async () =>
  (await axios.get('reports/site_hierarchy')).data;

export const getReviewStatusByTransIds = async (transactionIds) => {
  const queryStr = transactionIds.join('&id=');
  const {data} = await instance.get(`/transactions/metadata?id=${queryStr}`);

  const reviewStatus = Object.values(data).map((obj) => {
    return {
      transId: obj.transId,
      reviewed: obj.reviewAttributes.reviewed,
    };
  });

  return reviewStatus;
};

export const updateReviewStatusByTransId = async (data, transId, value) => {
  const valToStr = !!(value === 1 || value === 'true');
  const res = await instance.put(`/transactions/metadata/${transId}`, {
    ...data,
    reviewAttributes: {reviewed: valToStr},
  });
  return res;
};

export const getReportHistory = async (limit, offset) =>
  (await axios.get(`reports/history`, {
    params: {
      limit,
      offset,
    },
  })).data;

export const getDriveThruInsights = async (params, pointEvent) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);
  return (await axios.get('/drive_thru/occupancy', {
    params: {
      ...apiParams,
      limit: params.limit,
      offset: params.offset,
    },
  })).data;
};
export const getVehicleJourneyTimes = async (
  params,
  drilldownState,
  sites,
  pointEvent,
) => {
  const apiParams = extractWidgetApiParams(params, pointEvent);
  return (await axios.get('/drive_thru/vehicle_journey_times', {
    params: {
      drilldown_state: drilldownState,
      bi_site_ids: sites,
      ...apiParams,
    },
  })).data;
};
export const getJourneySegmentDetail = async (journeyUuid) =>
  (await axios.get(`/reports/journey_segment/${journeyUuid}`)).data;
