const tooltipProvider = (element) => {
  const {points} = element;
  let count;
  let avgJourneyTimeS;
  // Both series enabled
  if (points.length === 2) {
    count = points[0].y;
    avgJourneyTimeS = points[1]?.y;
  }
  // Legend filter
  // Vehicle Count series
  else if (points[0].series.name === 'Vehicle Count') {
    count = points[0].y;
  }
  // Avg Journey Time Series
  else {
    avgJourneyTimeS = points[0].y;
  }

  // put avg journey seconds into mm:ss format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}m ${formattedSeconds}s`;
  };

  let avgJourneyTimeFormatted;
  if (avgJourneyTimeS !== undefined) {
    avgJourneyTimeFormatted = formatTime(avgJourneyTimeS);
  }

  // Remove TZ from string
  const dateStr = element.key.toUTCString().slice(0, -3);

  return `
          <div>
            <span>${dateStr}</span><br>
             ${
               count !== undefined
                 ? `<span id="tooltip-dollars">
              Vehicle Count: ${count}
            </span><br>`
                 : ''
             }
            <span> ${
              avgJourneyTimeS !== undefined
                ? `Avg Journey Time: ${avgJourneyTimeFormatted}`
                : ''
            }</span>
          </div>
          `;
};

export const extraOptions = (theme) => {
  return {
    chart: {
      zooming: {
        type: 'x',
      },
    },
    tooltip: {
      shared: true,
      formatter: function tooltipWrapper() {
        return tooltipProvider(this, 'Hello');
      },
      useHTML: true,
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      area: {
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [[0, 'rgb(199, 113, 243)'], [0.7, theme.palette.primary.main]],
        },
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
        point: {
          events: {
            click(event) {
              if (event.point.options.url) {
                window.location.href = event.point.options.url.reportUrl;
              }
            },
          },
        },
      },
      series: {
        cropThreshold: 2200,
        turboThreshold: 2200,
      },
      line: {
        point: {
          events: {
            click(event) {
              if (event.point.options.url) {
                window.location.href = event.point.options.url.reportUrl;
              }
            },
          },
        },
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: '%I:%M %p',
        minute: '%H:%M',
        second: '%H:%M:%S',
      },
    },
    yAxis: [
      {
        title: {
          text: 'Vehicle Counts',
        },
      },
      // {
      //   title: {
      //     text: 'Total Sales',
      //   },
      // },
      {
        title: {
          text: 'Average Journey Dwell',
        },
        opposite: true,
        labels: {
          formatter() {
            const totalSeconds = this.value;
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${minutes}m ${formattedSeconds}s`;
          },
        },
      },
    ],
  };
};
