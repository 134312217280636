import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from '@mui/material';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import InsightsFilter from '../../../../pages/reports/newReportsPage/reportFilters/filterTypes/InsightsFilter';
import {getReports} from '../../../../api/reports';

const DriveThruHeaderWidget = ({
  currentUser,
  showButton,
  widgetConfig,
  filtering,
}) => {
  const [report, setReport] = useState('');
  const reportName = 'Drive-Thru Performance';
  useEffect(() => {
    const getData = async () => {
      const {results} = await getReports('operations', 'baseReports');
      const driveThruReport = results.filter(
        (r) => r.displayName === reportName,
      );
      setReport(driveThruReport[0]?.id || undefined);
    };

    getData();
  }, []);

  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Drive Thru Insights'}
      subtitle={widgetConfig.description || ''}
      actions={
        showButton && report
          ? [
              {
                id: 'link-button',
                label: 'Go To Full Report',
                ButtonProps: {
                  component: Link,
                  href: `/ui/operations/reports/${report}`,
                },
              },
            ]
          : []
      }
      filters={
        currentUser.settings.insightsDateSiteFilter && filtering ? (
          <InsightsFilter filtering={filtering} />
        ) : null
      }
      {...widgetConfig.properties}
    />
  );
};

DriveThruHeaderWidget.propTypes = {
  currentUser: PropTypes.shape({
    settings: PropTypes.shape({
      insightsDateSiteFilter: PropTypes.bool,
    }),
  }).isRequired,
  showButton: PropTypes.bool,
  widgetConfig: PropTypes.shape({
    widgetReference: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    properties: PropTypes.shape({}),
  }).isRequired,
  filtering: PropTypes.shape({}),
};

DriveThruHeaderWidget.defaultProps = {
  showButton: false,
  filtering: undefined,
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(DriveThruHeaderWidget);
