import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useReducer,
} from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  Autocomplete,
  TextField,
  Stack,
} from '@mui/material';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import CallbackFilterField from '../../../../shared/components/callbackFilterField';
import {getSafetyButtonsSummaryForSites} from '../../../../api/alarms';
import ButtonModeChip from './ButtonModeChip';
import AlarmDrawer from '../AlarmDrawer';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import SafetyButtonSection from './SafetyButtonSection';
import {getSiteNotificationSubscriptions} from '../../../../api/notifications';
import {subscriptionEventTypes} from '../notifications/utils';
import {getCamerasBySiteId} from '../../../../api/cameras';
import {AlarmSiteConfigurationContextProvider} from '../AlarmSiteConfigurationContext';
import SafetyButtonNotifications from './SafetyButtonNotifications';
import {handleFilterChange, filterReducer} from '../filterReducer';
import {useApi, useDialog} from '../../../../shared/hooks';
import SafetyButtonDialog from './safetyButtonDialog';
import CapabilitiesGuard from '../../../../shared/components/capabilitiesGuard';
import {PANIC_BUTTON_ADMIN} from '../../../../shared/util/allowed';
import {getSafetyButtons} from '../../../../api/soracom';

const columnDefinitions = [
  {
    id: 'siteName',
    label: 'Site Name',
  },
  {
    id: 'mode',
    label: 'Button Modes',
    notSortable: true,
  },
];

const initialOrderOptions = {
  initialOrderBy: 'siteName',
  initialOrder: 'asc',
};

const modeToLabel = (mode) => startCase(lowerCase(mode));

const buttonModes = ['active', 'eventsOnly', 'test', 'inactive'];
const ButtonsModes = ({buttons}) => {
  const totalButtons = buttonModes.reduce(
    (total, mode) => total + (buttons[mode] || 0),
    0,
  );

  if (buttons.active === totalButtons) {
    return <ButtonModeChip mode="active" customLabel="All Active" />;
  }

  return (
    <Box sx={{display: 'flex', gap: 1}}>
      {buttonModes
        .filter((mode) => buttons[mode])
        .map((mode) => (
          <ButtonModeChip
            key={mode}
            mode={mode}
            customLabel={`${buttons[mode]} ${modeToLabel(mode)}`}
          />
        ))}
    </Box>
  );
};

ButtonsModes.propTypes = {
  buttons: PropTypes.shape({
    active: PropTypes.number,
    eventsOnly: PropTypes.number,
    test: PropTypes.number,
    inactive: PropTypes.number,
  }).isRequired,
};

const ConfigurationSection = ({currentUser, snackbar}) => {
  const isNewNavigation = isNewNavigationWebEnabled(currentUser);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [siteSubscriptions, setSiteSubscriptions] = useState([]);
  const [siteCameras, setSiteCameras] = useState([]);
  const [reloadDataTrigger, setReloadDataTrigger] = useState(false); // State variable to trigger site data reload
  const [
    panicButtonDialogOpen,
    handlePanicButtonDialogOpen,
    handlePanicButtonDialogClose,
  ] = useDialog();
  const [showAddButton, setShowAddButton] = useState(true);
  const [getButtonsResponse, getButtonsLoading, , getButtonsApi] = useApi(
    getSafetyButtons,
  );

  useEffect(() => {
    const getPanicButtonSubscriptions = async () => {
      setSiteSubscriptions(
        await getSiteNotificationSubscriptions({
          type: 'noonlight',
          eventType: subscriptionEventTypes.panic,
        }),
      );
    };
    getPanicButtonSubscriptions();
  }, []);

  useEffect(
    () => {
      if (selectedSite && !drawerOpened) {
        getButtonsApi(selectedSite.siteId);
      }
    },
    [getButtonsApi, selectedSite, drawerOpened],
  );
  useEffect(
    () => {
      if (selectedSite) {
        getCamerasBySiteId(selectedSite.siteId).then(setSiteCameras);
      }
    },
    [selectedSite],
  );

  const [{isSearchValueValid, searchValue}, dispatch] = useReducer(
    filterReducer,
    {
      isSearchValueValid: true,
      searchValue: '',
    },
  );

  const [buttonModesFilter, setButtonModesFilter] = useState([]);
  const onLoadData = useCallback(
    async (page, limit, sortingOrder) => {
      const {count, results: sites} = await getSafetyButtonsSummaryForSites(
        page * limit,
        limit,
        sortingOrder,
        buttonModesFilter.length ? buttonModesFilter.join() : undefined,
        searchValue.length ? searchValue : undefined,
      );

      return {
        count,
        results: sites.map((result) => ({
          ...result,
          mode: <ButtonsModes buttons={result.buttons} />,
        })),
      };
    },
    [buttonModesFilter, searchValue],
  );

  const hasPanicButtonSubscription =
    selectedSite &&
    siteSubscriptions.some(({siteId}) => siteId === selectedSite.siteId);

  const onRowClick = (siteData) => {
    setSelectedSite((prev) => {
      // guard for same site click
      if (prev?.siteId !== siteData.siteId) {
        setSiteCameras([]);
      }
      setDrawerOpened(true);
      return siteData;
    });
  };
  return (
    <Fragment>
      <Grid
        container
        justifyContent="flex-end"
        spacing={{xs: 0.5, sm: 2, md: 2, lg: 2, xl: 3}}
      >
        <Grid item xs={12} md={4} lg={4}>
          <Autocomplete
            id="button-mode-filter"
            size="small"
            options={buttonModes.map((m) => ({label: modeToLabel(m)}))}
            onChange={(_, value) => {
              setButtonModesFilter(value.map(({label}) => snakeCase(label)));
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Button Mode" />
            )}
            ListboxProps={{'data-cy': 'button-mode-filter-option-list'}}
            isOptionEqualToValue={(opt, val) => opt.label === val.label}
            multiple
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <CallbackFilterField
            variant="outlined"
            size="small"
            sx={{width: '100%', margin: 0}}
            data-cy="site-panic-search"
            isDebounced
            filterValue={searchValue}
            onFilter={(value) => handleFilterChange(dispatch, value)}
            error={!isSearchValueValid}
            helperText={
              isSearchValueValid ? null : 'Please enter at least 3 characters'
            }
          />
        </Grid>
        <CapabilitiesGuard allowed={[PANIC_BUTTON_ADMIN]}>
          <Grid item xs={12} md={4} lg={4}>
            {!panicButtonDialogOpen && showAddButton && (
              <Button
                variant="outlined"
                data-cy="internal-open-panic-dialog-button"
                fullWidth
                onClick={() => {
                  setDrawerOpened(false);
                  setShowAddButton(false);
                }}
              >
                Add New Button +
              </Button>
            )}
            {(!showAddButton || panicButtonDialogOpen) && (
              <Autocomplete
                id="create-button-site-picker"
                size="small"
                options={siteSubscriptions}
                getOptionLabel={(o) => o.siteName}
                getOptionKey={(o) => o.siteId}
                blurOnSelect
                onChange={(_, site) => {
                  setSelectedSite(site);
                  setSiteCameras([]);
                  handlePanicButtonDialogOpen();
                }}
                onClose={() => setShowAddButton(true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Site"
                    autoFocus
                    onFocus={() => setDrawerOpened(false)}
                  />
                )}
                ListboxProps={{'data-cy': 'add-button-site-list'}}
              />
            )}
            {selectedSite && (
              <SafetyButtonDialog
                dialogOpen={panicButtonDialogOpen}
                onDialogClose={() => {
                  setSelectedSite(null);
                  setSiteCameras([]);
                  setShowAddButton(true);
                  handlePanicButtonDialogClose();
                  snackbar.close();
                }}
                cameras={siteCameras}
                snackbar={snackbar}
                safetyButtons={getButtonsResponse?.results ?? []}
                siteId={selectedSite.siteId}
                siteName={selectedSite.siteName}
                isLoading={getButtonsLoading}
                onDialogReload={() => {
                  getButtonsApi(selectedSite.siteId);
                  setReloadDataTrigger((prev) => !prev);
                }}
              />
            )}
          </Grid>
        </CapabilitiesGuard>
        <Grid data-cy="sites-table" item xs={12} display="grid">
          <CollapsibleTable
            rowId="siteId"
            columns={columnDefinitions}
            sortable
            orderOptions={initialOrderOptions}
            onLoadData={onLoadData}
            reloadData={reloadDataTrigger}
            onRowClick={onRowClick}
            paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
            stickyHeader={false}
            selectedId={selectedSite?.siteId}
          />
        </Grid>
      </Grid>

      <AlarmDrawer
        onClose={() => {
          setDrawerOpened(false);
        }}
        open={drawerOpened}
        newNavigationEnabled={isNewNavigation}
      >
        <AlarmSiteConfigurationContextProvider>
          {selectedSite && drawerOpened && (
            <Fragment>
              <Typography
                name="site-name"
                sx={{paddingLeft: 2, paddingTop: 2}}
                variant="h6"
                gutterBottom
              >
                {selectedSite.siteName}
              </Typography>
              <Stack
                divider={<Divider variant="middle" flexItem />}
                spacing={2}
                sx={{px: 4}}
              >
                {hasPanicButtonSubscription && siteCameras?.length > 0 && (
                  <SafetyButtonSection
                    name="safety-button"
                    selectedSiteId={selectedSite.siteId}
                    selectedSiteName={selectedSite.siteName}
                    cameras={siteCameras}
                    snackbar={snackbar}
                    onSafetyButtonsChange={
                      () => setReloadDataTrigger((prev) => !prev) // Toggle the reload site data trigger
                    }
                  />
                )}

                {hasPanicButtonSubscription && (
                  <SafetyButtonNotifications
                    site={{
                      id: selectedSite.siteId,
                      name: selectedSite.siteName,
                    }}
                  />
                )}
              </Stack>
            </Fragment>
          )}
        </AlarmSiteConfigurationContextProvider>
      </AlarmDrawer>
    </Fragment>
  );
};

ConfigurationSection.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
};

export default ConfigurationSection;
