import {cloneDeep, merge} from 'lodash';
import {defaultHighChartsOptionsJson} from './highChartsOptionsJson';

export const createDefaultHighChartsJson = (
  chartEvents,
  graphSettings,
  tooltipProvider,
) => {
  return Object.assign(
    {},
    {
      chart: {
        type: graphSettings.chartType,
        events: {
          async load() {
            // Fetch and set initial data here
            try {
              this.showLoading();
              const series = await chartEvents.onLoad();
              if (series !== null) {
                if (series.length < 1) {
                  this.showNoData('No data to display');
                } else {
                  series.map((s) => this.addSeries(s));
                }
              }
            } catch (e) {
              this.showNoData('Error loading data');
            } finally {
              this.hideLoading();
            }
          },
          async drilldown(event) {
            try {
              this.showLoading();
              const series = await chartEvents.onDrilldown(event);
              if (series.length < 1) {
                this.showNoData('No data to display');
              } else {
                const combinedData = [];
                series.forEach((originalSeries) => {
                  // assume originalSeries.data is an array of points
                  combinedData.push(...originalSeries.data);
                });

                const singleDrilldownSeries = {
                  id: event.point.drilldown, // match the parent point’s drilldown ID
                  name: graphSettings.widgetName,
                  type: graphSettings.chartType,
                  data: combinedData, // put everything in one big array
                };

                // Then add just once
                this.addSeriesAsDrilldown(event.point, singleDrilldownSeries);
              }
            } catch (e) {
              this.showNoData('Error loading data');
            } finally {
              this.hideLoading();
            }
          },
          drillUp() {
            chartEvents.onDrillUp();
          },
        },
      },
      title: {
        text: graphSettings.title,
        margin: parseFloat(graphSettings.theme.spacing(5)),
      },
      subtitle: {
        text: graphSettings.subtitle,
        style: {
          color: graphSettings.theme.palette.primary.light,
          fontSize: graphSettings.theme.typography.caption.fontSize,
        },
      },
      xAxis: {
        title: graphSettings.xAxisTitle,
      },
      yAxis: [
        {
          title: {
            text: graphSettings.yAxisTitle,
          },
        },
      ],
      plotOptions: {
        // General settings for all series
        series: {},
        // General settings for columns
        column: {
          minPointLength: 5,
          color: graphSettings.columnColor,
          pointPadding: parseFloat(graphSettings.theme.spacing(0)),
          states: {
            hover: {
              color: graphSettings.hoverColor,
            },
          },
        },
      },
    },
    // If tooltipProvider exist we will add it to the json, else default tooltip
    tooltipProvider
      ? {
          tooltip: {
            formatter: function tooltipWrapper() {
              return tooltipProvider(this, graphSettings.tipText);
            },
            useHTML: true,
          },
        }
      : {},
  );
};

export const createHighChartJson = (
  chartEvents,
  extraOptions,
  graphSettings,
  tooltipProvider,
) => {
  const initialJson = createDefaultHighChartsJson(
    chartEvents,
    graphSettings,
    tooltipProvider,
  );

  const extraOptionsJson = extraOptions
    ? extraOptions(graphSettings.theme)
    : {};

  return merge(
    cloneDeep(defaultHighChartsOptionsJson),
    cloneDeep(initialJson),
    cloneDeep(extraOptionsJson),
  );
};
