import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import HighChartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import Drilldown from 'highcharts/modules/drilldown';
import {createHighChartJson} from '../highChartsUtil';

const ZoomableChart = (props) => {
  const chartComponent = useRef(null);
  const {graphSettings, extraOptions, onLoad, onDrilldown, params} = props;
  const chartOptionsJson = createHighChartJson(
    {
      onLoad,
      onDrilldown,
    },
    extraOptions,
    graphSettings,
    null,
  );

  NoDataToDisplay(Highcharts);
  Drilldown(Highcharts);
  HighchartsAccessibility(Highcharts);

  return (
    <HighChartsReact
      id={`${graphSettings.widgetName}-id`}
      key={`${JSON.stringify(params)}-${graphSettings.widgetName}`}
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptionsJson}
    />
  );
};

export default ZoomableChart;

ZoomableChart.propTypes = {
  graphSettings: PropTypes.shape({
    chartType: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    plotLinesLabel: PropTypes.string,
    columnColor: PropTypes.string,
    hoverColor: PropTypes.string,
    widgetName: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  onDrilldown: PropTypes.func.isRequired,
  extraOptions: PropTypes.func,
  tooltipProvider: PropTypes.func,
};

ZoomableChart.defaultProps = {
  extraOptions: null,
  tooltipProvider: null,
};
