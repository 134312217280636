import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import ColumnRangeChart from '../../../../shared/components/highcharts/columnRangeChart';
import {getVehicleJourneyTimes} from '../../../../api/reports';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

import {
  getGraphSettings,
  seriesFromData,
  seriesConfigsDrilldown,
  seriesConfigsTop,
  formatSecondsToMinutes,
} from './driveThruJourneyUtils';

const DriveThruJourneyWidget = ({currentUser, widgetConfig, filtering}) => {
  const theme = useTheme();
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;

  const params = {
    filters: filtering?.filters,
    reportLookBackDays,
    currentUserDate,
  };

  const extraOptions = () => {
    return {
      chart: {
        inverted: true,
      },
      xAxis: [
        {
          type: 'category',
          showEmpty: false,
        },
        {
          type: 'datetime',
          showEmpty: false,
        },
      ],
      yAxis: [
        {
          title: {
            text: 'Dwell Times (min)',
          },
          labels: {
            formatter() {
              // highcharts supplies 'this' in context
              // eslint-disable-next-line react/no-this-in-sfc
              const totalSeconds = this.value;
              const min = Math.floor(totalSeconds / 60);
              const sec = totalSeconds % 60;
              const secondStr = String(sec).padStart(2, '0');
              return `${min}:${secondStr}`;
            },
          },
        },
      ],
      plotOptions: {
        columnrange: {
          color: theme.palette.primary.main,
          borderRadius: '50%',
          dataLabels: {
            enabled: true,
            formatter() {
              // highcharts provides 'this' in its context
              // eslint-disable-next-line react/no-this-in-sfc
              return `${formatSecondsToMinutes(this.y)} ${
                // eslint-disable-next-line react/no-this-in-sfc
                this.y > 60 ? 'm' : 's'
              }`;
            },
          },
          pointWidth: 15,
          point: {
            events: {
              click(event) {
                if (event.point.options.url) {
                  window.location.href = event.point.options.url;
                }
              },
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
    };
  };
  const onLoad = async () => {
    const data = await getVehicleJourneyTimes(params, 'By Location');
    if (data.results.length > 0) {
      return seriesFromData(seriesConfigsTop(theme), data.results);
    }
    return [];
  };
  const onDrilldown = async (event) => {
    const site = event.point.options.storeCode;
    const data = await getVehicleJourneyTimes(params, 'By Day/Location', site);
    const res = data.results.map((result) => {
      const date = DateTime.fromISO(result.day, {zone: 'utc'});
      const formattedDay = date.toFormat('MMM dd, yyyy');

      const finalResult = {
        ...result,
        day: formattedDay,
      };

      return finalResult;
    });

    if (res.length > 0) {
      return seriesFromData(seriesConfigsDrilldown(), res);
    }
    return [];
  };

  const getContent = () => {
    const graphSettings = getGraphSettings(theme);
    return (
      <ColumnRangeChart
        graphSettings={graphSettings}
        onDrilldown={onDrilldown}
        onLoad={onLoad}
        extraOptions={extraOptions}
        params={params}
      />
    );
  };
  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(DriveThruJourneyWidget);

DriveThruJourneyWidget.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  widgetConfig: PropTypes.shape({}).isRequired,
  filtering: PropTypes.shape({}).isRequired,
};
