import React, {useRef} from 'react';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import HighChartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import Drilldown from 'highcharts/modules/drilldown';
import HighchartsMore from 'highcharts/highcharts-more';
import {createHighChartJson} from '../highChartsUtil';
import {withCurrentUser} from '../../../contexts/currentUserContext';

const ColumnRangeChart = (props) => {
  const chartComponent = useRef(null);
  const {graphSettings, extraOptions, onLoad, onDrilldown, params} = props;

  const chartOptionsJson = createHighChartJson(
    {
      onLoad,
      onDrilldown,
    },
    extraOptions,
    graphSettings,
    null,
  );

  NoDataToDisplay(Highcharts);
  Drilldown(Highcharts);
  HighchartsAccessibility(Highcharts);
  HighchartsMore(Highcharts);

  return (
    <HighChartsReact
      id={`${graphSettings.widgetName}-id`}
      key={`${JSON.stringify(params)}-${graphSettings.widgetName}`} // re-mounts on filter
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptionsJson}
    />
  );
};

export default compose(withCurrentUser)(ColumnRangeChart);

ColumnRangeChart.propTypes = {
  graphSettings: PropTypes.shape({
    chartType: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    plotLinesLabel: PropTypes.string,
    columnColor: PropTypes.string,
    hoverColor: PropTypes.string,
    widgetName: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  onDrilldown: PropTypes.func.isRequired,
  extraOptions: PropTypes.shape({}),
  params: PropTypes.shape({}).isRequired,
  tooltipProvider: PropTypes.func,
};

ColumnRangeChart.defaultProps = {
  extraOptions: null,
  tooltipProvider: null,
};
