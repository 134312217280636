import React, {useState, useRef, useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {DateTime} from 'luxon';
import PoliceContactedWidget from '../policeContactedWidget';
import LateDepartureWidget from '../lateDepartureWidget';
import SspTableWidget from '../SspTableWidget';
import TotalAlarmsWidget from '../totalAlarmsWidget';
import {
  alarmLabels,
  alarmDispatches,
} from '../../../sites/details/alarmEventsSummary/utils';
import {getAlarmStatisticsForSites} from '../../../../api/alarms';
import {prepareStatus, handleVerificationFiltering} from '../sspUtils';
import {useSsp} from '../hooks';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const ColumnGridContainer = ({children, GridContainerProps, ...rest}) => (
  <Grid item {...rest}>
    <Grid
      container
      sx={{
        flexDirection: {xs: 'row', lg: 'column'},
      }} // MUI prop 'direction' as it does not support the breakpoints props
      {...GridContainerProps}
    >
      {children}
    </Grid>
  </Grid>
);

const SspTab = ({filters, setFilters, currentUser}) => {
  const [auxOptions, setAuxOptions] = useState();
  const sspTableRef = useRef(null);

  const [alarmSummaryLoading, alarmSummary] = useSsp(
    useCallback((startDate, endDate, signal) => {
      const alarmStatistics = getAlarmStatisticsForSites(
        startDate,
        endDate,
        [
          alarmDispatches.serviceContacted,
          alarmDispatches.canceled,
          alarmDispatches.closed,
        ],
        [alarmLabels.eventsOnly.name],
        [alarmLabels.lateDeparture.name],
        signal,
      );

      return alarmStatistics;
    }, []),
    filters.date.startDate,
    filters.date.endDate,
  );

  const handleLegendClick = (event) => {
    const selectedStatus = prepareStatus(event.target.name);
    setFilters((prev) => handleVerificationFiltering(prev, selectedStatus));
  };

  const dispatches = alarmSummary?.dispatches;
  const policeContactedWidgetData = dispatches
    ? {
        ...dispatches,
        closed:
          dispatches.closed - dispatches.canceled - dispatches.serviceContacted,
      }
    : {};

  return (
    <Fragment>
      <ColumnGridContainer
        xs={12}
        lg={3}
        GridContainerProps={{
          rowSpacing: {xs: 0, sm: 0, md: 0, lg: 3, xl: 3},
          columnSpacing: {xs: 2, sm: 2, md: 2, lg: 0, xl: 0},
        }}
      >
        <PoliceContactedWidget
          id="police-contacted"
          xs={6}
          lg={12}
          buttonLabel="View by Site"
          onActionClick={() => {
            sspTableRef.current?.scrollIntoView({behavior: 'smooth'});
            setAuxOptions({orderBy: 'serviceContacted', order: 'desc'});
          }}
          data={policeContactedWidgetData}
          loading={alarmSummaryLoading}
        />
        <LateDepartureWidget
          id="late-departures"
          xs={6}
          lg={12}
          data={{
            lateDepartures: alarmSummary?.labels?.lateDeparture ?? 0,
            otherNoThreats:
              (alarmSummary?.no ?? 0) -
              (alarmSummary?.labels?.lateDeparture ?? 0),
          }}
          onActionClick={() => {
            sspTableRef.current?.scrollIntoView({behavior: 'smooth'});
            setAuxOptions({orderBy: 'lateDeparture', order: 'desc'});
          }}
          loading={alarmSummaryLoading}
          buttonLabel="View by Site"
        />
      </ColumnGridContainer>
      <TotalAlarmsWidget
        xs={12}
        lg={9}
        width={100} // fix the MUI 5 Grid negative margin issue
        startDate={filters.date.startDate}
        endDate={filters.date.endDate}
        onLegendClick={handleLegendClick}
      />
      <SspTableWidget
        ref={sspTableRef}
        currentUser={currentUser}
        auxOptions={auxOptions}
        setAuxOptions={setAuxOptions}
        startDate={filters.date.startDate}
        endDate={filters.date.endDate}
        verifications={filters.verifications}
      />
    </Fragment>
  );
};

SspTab.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.instanceOf(DateTime),
    endDate: PropTypes.instanceOf(DateTime),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
};

export default withCurrentUser(SspTab);
