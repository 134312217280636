import React, {useEffect, useState, useContext} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from '@mui/material';
import {PropTypes} from 'prop-types';

import Spinner from '../../../../shared/components/spinner';
import RefContext from './AlarmsRefContext';
import {UsersListAlert} from './UsersListAlerts';
import UsersNotificationList from './UsersNotificationList';
import {
  useNotificationSubscriptions,
  notificationSubscriptionStatuses,
} from './hooks';
import {subscriptionEventTypes, checkboxStatuses} from './utils';

const NotificationDialog = (props) => {
  const {onClose, open, site, eventType, isEM} = props;
  const [checkboxStatus, setCheckboxStatus] = useState(checkboxStatuses.Idle);
  const [
    status,
    standardUsers,
    priorityUsers,
    totalUsersCount,
    siteSubscriptions,
    onGetMoreUsers,
    onSearchUsers,
    searchValue,
  ] = useNotificationSubscriptions(site.id, eventType);
  const {register} = useContext(RefContext);

  const contentVisibility =
    status === notificationSubscriptionStatuses.Success ||
    notificationSubscriptionStatuses.Reloading ||
    notificationSubscriptionStatuses.Terminated;

  useEffect(
    () => {
      if (checkboxStatus === checkboxStatuses.Success) {
        onGetMoreUsers(true);
        setCheckboxStatus(checkboxStatuses.Idle);
      }
    },
    [checkboxStatus, onGetMoreUsers],
  );

  useEffect(
    () => {
      if (open) {
        onGetMoreUsers(true);
        setCheckboxStatus(checkboxStatuses.Idle);
      }

      register('setDataNewUsers', onGetMoreUsers);
      return () => {
        searchValue.current = '';
        register('setDataNewUsers', null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  const displayDialogTitle = () => {
    switch (eventType) {
      case subscriptionEventTypes.video:
        return 'Video Alarms Notification Configuration';
      case subscriptionEventTypes.panel:
        return 'Intrusion Panel Notification Configuration';
      case subscriptionEventTypes.panic:
        return 'Panic Alarms Notification Configuration';
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      data-cy="notification-dialog"
    >
      <DialogTitle>{displayDialogTitle()}</DialogTitle>
      {contentVisibility && siteSubscriptions && priorityUsers ? (
        <DialogContent sx={{height: '100%', overflow: 'hidden'}}>
          <Typography variant="h6" data-cy="notification-dialog-name">
            {site.name}
          </Typography>
          <UsersListAlert
            showError={checkboxStatus === checkboxStatuses.Error}
            eventType={eventType}
            isEM={isEM}
          />
          <Box
            sx={[
              (theme) => ({
                ...theme.mixins.scrollable(300),
              }),
              {
                marginLeft: 1,
                marginTop: 1,
                borderBottom: 1,
                borderColor: 'divider',
              },
            ]}
          >
            <UsersNotificationList
              standardUsers={standardUsers}
              priorityUsers={priorityUsers}
              onSearchUsers={onSearchUsers}
              searchValue={searchValue}
              dataSubscriptions={siteSubscriptions}
              siteId={site.id}
              eventType={eventType}
              checkboxStatus={checkboxStatus}
              setCheckboxStatus={setCheckboxStatus}
              isEM={isEM}
              id="users-notification-list"
            />
            {status !== notificationSubscriptionStatuses.Terminated &&
              standardUsers && (
                <Box
                  sx={{
                    marginY: 2,
                    textAlign: 'center',
                  }}
                >
                  {status === notificationSubscriptionStatuses.Reloading ? (
                    <Spinner size={40} color="primary" />
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => onGetMoreUsers(false)}
                    >
                      Load More
                    </Button>
                  )}
                </Box>
              )}
          </Box>

          <Typography
            data-cy="users-count"
            variant="body2"
            fontStyle="italic"
            marginTop={3}
          >
            {totalUsersCount.current ?? 0} users with access to the site
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent sx={{height: '100vh', overflow: 'hidden'}}>
          <Spinner size={60} color="primary" />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="text"
          autoFocus
          onClick={onClose}
          data-cy="close-dialog-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotificationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  site: PropTypes.shape({id: PropTypes.string, name: PropTypes.string})
    .isRequired,
  eventType: PropTypes.oneOf(Object.values(subscriptionEventTypes)).isRequired,
  isEM: PropTypes.bool.isRequired,
};

export default NotificationDialog;
