import React from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import compose from 'lodash/flowRight';

import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getNavigationItems} from '../../shared/components/getNavigationItems';
import {getTabs} from './homePillarRoutes';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';
import SiteSettings from '../sites/settingsNewNavigation';
import SitesSettingsNewNavRedirect from '../sites/shared/SitesSettingsNewNavRedirect';

const Home = ({currentUser, location}) => {
  const isSiteSettingsPageOpened = location.pathname.includes(
    'home/site-settings',
  );
  const isVideoPlayerPageOpened = location.pathname.includes('/video');

  if (isNewNavigationWebEnabled(currentUser)) {
    const tabs = getTabs(currentUser);
    const navigationProps = getNavigationItems(tabs, currentUser);
    return isSiteSettingsPageOpened ? (
      <Switch>
        <Route
          key="devices"
          path="/home/site-settings"
          component={SiteSettings}
        />
      </Switch>
    ) : (
      <PageTemplateResponsive
        PageHeaderProps={{title: 'Home'}}
        PageContentProps={
          isVideoPlayerPageOpened
            ? {maxWidth: null, marginX: null, marginY: null}
            : undefined
        }
        navigateToTopHierarchy
        {...navigationProps}
      >
        <SitesSettingsNewNavRedirect />
        <Route exact path="/home">
          {currentUser.settings.newDashboard ? (
            <RedirectWithQueryParams to="/home/dashboard" />
          ) : (
            <Redirect to="/home/sites" />
          )}
        </Route>
        <Switch>
          {tabs.map((tab) => (
            <Route
              exact={tab.key !== 'sites' && tab.key !== 'myClips'}
              key={tab.key}
              path={`/home/${tab.path}`}
              component={tab.component}
            />
          ))}
        </Switch>
      </PageTemplateResponsive>
    );
  }
  return <GlobalErrorPage />;
};
export default compose(
  withCurrentUser,
  withRouter,
)(Home);
