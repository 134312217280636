import axios from 'axios';

export const getReportId = async (title) =>
  (await axios.get(`/reports/${title}`)).data;

export const getTask = async (notificationId, taskId) =>
  (await axios.get(`/notifications/${notificationId}/tasks/${taskId}`)).data;

export const getSiteNotificationSubscriptions = async ({
  siteId,
  type,
  eventType,
}) =>
  (await axios.get('/notifications/subscriptions', {
    params: {
      siteId,
      type,
      eventType,
    },
  })).data;

export const editSiteSubscription = async (
  siteId,
  subscriptionId,
  subscription,
) =>
  (await axios.put(
    `/notifications/site/${siteId}/subscription/${subscriptionId}`,
    {
      ...subscription,
    },
  )).data;

export const registerPushNotificationDevice = async (userId, body) =>
  (await axios.post(`/notifications/user/${userId}/register_device`, body))
    .data;
