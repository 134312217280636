import React, {useReducer, useCallback, Fragment} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  useMediaQuery,
  Box,
  Alert,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import startCase from 'lodash/startCase';
import SafetyButtonCard from '../SafetyButtonCard';
import CameraListRow from '../../cameraConfiguration/CameraListRow';
import {formatMode} from '../../notifications/utils';
import CapabilitiesGuard from '../../../../../shared/components/capabilitiesGuard';
import {PANIC_BUTTON_ADMIN} from '../../../../../shared/util/allowed';

const checkMappingError = (mappedCameras, invalidCams) =>
  mappedCameras.some((camId) => invalidCams.has(camId));

const configReducer = (state, {type, checked = false, id = '', value = ''}) => {
  switch (type) {
    case 'CHANGE_MAPPING': {
      const cameraSet = new Set(state.mappedCameras);
      if (checked) cameraSet.add(id);
      else cameraSet.delete(id);

      return {
        ...state,
        mode: state.mode,
        mappedCameras: [...cameraSet],
        invalidCameraMapped: checkMappingError(
          [...cameraSet],
          state.invalidCams,
        ),
      };
    }
    case 'CHANGE_MODE': {
      return {...state, mode: value};
    }
    case 'CHANGE_NAME': {
      return {...state, name: value};
    }
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

const createInitialState = ({button, buttonCameras, siteCameras}) => {
  const invalidCams = new Set(
    siteCameras.reduce((acc, curr) => {
      if (!curr.isOnline || curr.recordMode === 'None') {
        acc.push(curr.id);
      }
      return acc;
    }, []),
  );
  const mappedCameras = buttonCameras?.map((cam) => cam.cameraId) ?? [];
  const invalidCameraMapped = checkMappingError(mappedCameras, invalidCams);
  return {
    mode: button.mode,
    name: button.name,
    mappedCameras,
    invalidCameraMapped,
    invalidCams,
  };
};

/** This dialog screen enables user to configure selected safety button  */
const ConfigContent = ({
  buttonData,
  safetyButtonCameras,
  cameras,
  onRevert,
  onSubmit,
  onDelete,
  isUpdating,
}) => {
  const [
    {mode, mappedCameras, name, invalidCameraMapped},
    dispatch,
  ] = useReducer(
    configReducer,
    {
      button: buttonData,
      buttonCameras: safetyButtonCameras,
      siteCameras: cameras,
    },
    createInitialState,
  );
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleChange = ({target: {checked, id}}) =>
    dispatch({type: 'CHANGE_MAPPING', checked, id});

  const handleChangeName = ({target: {value}}) =>
    dispatch({type: 'CHANGE_NAME', value});

  const handleChangeMode = ({target: {value}}) =>
    dispatch({type: 'CHANGE_MODE', value});

  const mappingMaxReached = mappedCameras.length > 3; // max 4 cameras mapped
  const mappingValid = mappedCameras.length === 4 && !invalidCameraMapped;

  const renderDialogAlert = useCallback(
    () => {
      if (invalidCameraMapped) {
        return (
          <Alert
            severity="error"
            data-cy="button-activation-alert-error"
            sx={{m: 2}}
          >
            One or more cameras mapped to the button are disabled or not
            recording. Please unmap and select a new camera.
          </Alert>
        );
      }
      if (!mappingMaxReached || !mappingValid) {
        return (
          <Alert
            data-cy="button-activation-alert-info"
            severity="info"
            sx={{m: 2}}
          >
            Please map exactly 4 cameras to this panic button
          </Alert>
        );
      }
      return (
        <Alert
          data-cy="button-activation-alert-warn"
          severity="success"
          sx={{m: 2}}
        >
          4 cameras have been mapped
        </Alert>
      );
    },
    [invalidCameraMapped, mappingMaxReached, mappingValid],
  );

  return (
    <Fragment>
      <DialogContent>
        <SafetyButtonCard
          sx={{width: '100%'}}
          buttonData={buttonData}
          fullWidth={smallScreen}
          buttonName={name}
          handleChangeName={handleChangeName}
          editMode
        >
          <FormControl fullWidth name="button-mode-select" variant="standard">
            <InputLabel id="panic-mode-label">Mode</InputLabel>
            <Select
              labelId="panic-mode-label"
              label="Mode"
              value={mode}
              onChange={handleChangeMode}
            >
              {['inactive', 'events_only', 'test', 'active'].map((val) => (
                <MenuItem key={val} value={val} data-cy={`button-${val}-mode`}>
                  {startCase(formatMode(val))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SafetyButtonCard>
        {cameras.length > 0 ? (
          <Fragment>
            <Grid item columns={12}>
              <Typography variant="h6">Edit Cameras</Typography>
            </Grid>
            {!smallScreen && (
              <Grid container paddingTop={1} alignItems="center">
                <Grid item md={2}>
                  <Typography variant="body1">Enabled&nbsp;</Typography>
                </Grid>
                <Grid item md={3} />
                <Grid item md={6}>
                  <Typography pl={2} variant="body1">
                    Camera Name&nbsp;
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Box
              sx={{display: 'grid', gridAutoRows: '1fr', gridAutoFlow: 'row'}}
              data-cy="safety-button-mapping-list"
            >
              {cameras.map((camera) => {
                const checked = mappedCameras.includes(camera.id.toString());

                const handleSmallScreenChange = ({id}) => {
                  dispatch({
                    type: 'CHANGE_MAPPING',
                    checked: !checked,
                    id,
                  });
                };

                return (
                  <CameraListRow
                    smallScreen={smallScreen}
                    key={camera.id}
                    onChange={handleChange}
                    onSmallScreenSelect={handleSmallScreenChange}
                    checked={checked}
                    camera={camera}
                    disableSelect={
                      !checked &&
                      (mappingMaxReached ||
                        !camera.isOnline ||
                        camera.recordMode === 'None')
                    }
                    mappingMaxReached={mappingMaxReached}
                  />
                );
              })}
            </Box>
          </Fragment>
        ) : (
          <Typography variant="body1">
            No enabled cameras within the site!
          </Typography>
        )}
      </DialogContent>
      {renderDialogAlert()}
      <DialogActions sx={{justifyContent: 'space-between'}}>
        <Box>
          <CapabilitiesGuard allowed={[PANIC_BUTTON_ADMIN]}>
            <Button
              sx={{
                visibility:
                  buttonData.mode === 'inactive' ? 'visible' : 'hidden',
              }}
              hidden
              variant="outlined"
              color="error"
              name="safety-button-dialog-deprovision-button"
              onClick={onDelete}
            >
              Deprovision
            </Button>
          </CapabilitiesGuard>
        </Box>

        <Stack direction="row" spacing={1}>
          <Button name="safety-button-dialog-back-button" onClick={onRevert}>
            Cancel
          </Button>
          <Button
            name="safety-button-dialog-save-button"
            variant="contained"
            onClick={() => {
              const objectToSend = {
                name,
                mode,
              };
              onSubmit(
                buttonData.integrationId,
                objectToSend,
                mappedCameras?.map((cam) => Number(cam)),
              );
            }}
            disabled={mode !== 'inactive' && (isUpdating || !mappingValid)}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};

export default ConfigContent;
