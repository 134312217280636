import React, {Fragment, useState, useRef, useCallback, useEffect} from 'react';
import {
  TextField,
  Dialog,
  Button,
  useTheme,
  ClickAwayListener,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import SiteHierarchyGridFilter from '../../../../../shared/components/siteHierarchyGridFilter';
import {getSiteNamesByStoreCode} from '../../utils';

const SiteHierarchyFilter = ({
  siteHierarchyData,
  filter,
  onChange,
  isSmallScreen,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [gridStyle, setGridStyle] = useState({});
  const filterRef = useRef(null);

  const [siteNamesByStoreCode, setSiteNamesByStoreCode] = useState(null);
  const [selectedSites, setSelectedSites] = useState([]);

  const theme = useTheme();
  const handlePopupModal = () => setPopupOpen(!popupOpen);

  useEffect(
    () => {
      if (siteHierarchyData != null && siteNamesByStoreCode == null) {
        setSiteNamesByStoreCode(getSiteNamesByStoreCode(siteHierarchyData[0]));
      }

      if (siteNamesByStoreCode != null) {
        setSelectedSites(
          filter.value
            .split(',')
            // Removed excessive values from location filter
            .filter((id) => siteNamesByStoreCode[id]),
        );
      }
    },
    [filter, siteHierarchyData, siteNamesByStoreCode],
  );

  const toggleOpen = () => {
    // pins the dialog to the location of the filter text field so it always renders underneath.
    if (filterRef.current) {
      const filterRect = filterRef.current.getBoundingClientRect();
      setGridStyle({
        position: 'fixed',
        top: filterRect.bottom + window.scrollY,
        left: filterRect.left + window.scrollX,
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 100,
        maxHeight: '40%',
        minWidth: '40%',
        overFlowY: 'auto',
      });
    }
    setExpanded((prev) => !prev);
  };

  const toggleClickAway = () => {
    setExpanded(false);
  };

  const onUpdate = useCallback(
    (newValue) => {
      onChange(
        newValue.join(','),
        newValue.length ? null : 'No Locations Selected',
      );
    },
    [onChange],
  );

  const isMobileDisplay = (values) => {
    if (isSmallScreen) {
      return (
        <Button variant="contained" onClick={handlePopupModal}>
          {values.length ? 'Confirm' : 'Cancel'}
        </Button>
      );
    }
    return null;
  };

  const renderTextField = (clickHandler) => {
    return (
      <TextField
        ref={filterRef}
        name="report-filter-site-hierarchy-field"
        placeholder={
          selectedSites.length
            ? `${selectedSites.length.toLocaleString()} Sites Selected`
            : 'Click here to Select Site(s)...'
        }
        error={!!filter?.error}
        fullWidth
        hiddenLabel
        onClick={clickHandler}
        inputProps={{readOnly: true}}
        sx={{
          padding: 1,
          '& .MuiInputBase-input': {
            color: 'transparent',
            textShadow: '0 0 0 #000',
          },
        }}
      />
    );
  };

  const renderGrid = (change, sites, data, styles) => {
    return (
      <SiteHierarchyGridFilter
        onChange={change}
        selectedSites={sites}
        data={data}
        gridStyles={styles}
      />
    );
  };

  return siteHierarchyData !== null ? (
    <Fragment>
      {isSmallScreen ? (
        <Fragment>
          {renderTextField(handlePopupModal)}
          <Dialog
            fullScreen
            open={popupOpen}
            onClose={handlePopupModal}
            maxWidth={false}
          >
            {renderGrid(onUpdate, selectedSites, siteHierarchyData, {})}
            {isMobileDisplay(selectedSites)}
          </Dialog>
        </Fragment>
      ) : (
        <ClickAwayListener onClickAway={toggleClickAway}>
          <Box sx={{position: 'relative'}}>
            {renderTextField(toggleOpen)}
            {expanded
              ? renderGrid(
                  onUpdate,
                  selectedSites,
                  siteHierarchyData,
                  gridStyle,
                )
              : null}
          </Box>
        </ClickAwayListener>
      )}
    </Fragment>
  ) : null;
};

export default SiteHierarchyFilter;

SiteHierarchyFilter.propTypes = {
  filter: PropTypes.shape({
    index: PropTypes.number,
    paramName: PropTypes.string,
    operatorType: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isSmallScreen: PropTypes.bool,
};

SiteHierarchyFilter.defaultProps = {
  filter: {},
  onChange: () => {},
  isSmallScreen: false,
};
