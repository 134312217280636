import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {useApi} from '../../../../shared/hooks';
import {
  alarmDispatches,
  alarmLabels,
} from '../../../../pages/sites/details/alarmEventsSummary/utils';
import {getAlarmStatisticsForSites} from '../../../../api/alarms';
import SspPoliceContactedWidget from '../../../../pages/smartSiteProtection/landingPage/policeContactedWidget';
import {openInOneTab} from '../../utils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const currentTimestamp = DateTime.local().toUTC();
const weekAgoTimestamp = DateTime.local()
  .minus({
    day: 7,
  })
  .toUTC();

const PoliceContactedWidget = ({widgetConfig, currentUser}) => {
  const [alarms, loading, , getAlarms] = useApi(getAlarmStatisticsForSites);
  useEffect(
    () => {
      getAlarms(
        weekAgoTimestamp,
        currentTimestamp,
        [
          alarmDispatches.serviceContacted,
          alarmDispatches.canceled,
          alarmDispatches.closed,
        ],
        [alarmLabels.eventsOnly.name],
        [alarmLabels.lateDeparture.name],
      );
    },
    [getAlarms],
  );

  const dispatches = alarms?.dispatches;
  const policeContactedWidgetData = dispatches
    ? {
        ...dispatches,
        closed:
          dispatches.closed - dispatches.canceled - dispatches.serviceContacted,
      }
    : {};

  const handleClick = () => {
    window.open(
      `/ui/ssp?passedSortingOrder=serviceContacted`,
      openInOneTab(currentUser),
    );
  };

  return (
    <SspPoliceContactedWidget
      id={widgetConfig.widgetReference}
      onActionClick={handleClick}
      loading={loading}
      data={policeContactedWidgetData}
      buttonIcon={<OpenInNewIcon />}
      buttonLabel="View More Details"
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(PoliceContactedWidget);
