import {React, forwardRef, memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {DateTime} from 'luxon';
import {snakeCase} from 'lodash';
import queryString from 'query-string';

import CollapsibleTable from '../../../shared/components/collapsibleTable';
import {getAlarmForSitesByLabelsByDispatch} from '../../../api/alarms';
import {verificationStatuses} from './sspUtils';
import {openInOneTab} from '../../../apps/dashboard/utils';
import {isNewNavigationWebEnabled} from '../../../shared/util/user';

const SspTableWidget = forwardRef((props, ref) => {
  const {
    auxOptions,
    setAuxOptions,
    startDate,
    endDate,
    verifications,
    currentUser,
  } = props;
  const columnsDefinition = [
    {
      id: 'siteName',
      label: 'Site Name',
      style: {minWidth: 150},
    },
    {
      id: 'serviceContacted',
      label: 'Police Contacted',
      style: {minWidth: 150},
    },
    {
      id: 'lateDeparture',
      label: 'Late Departure',
      style: {minWidth: 150},
    },
    {
      id: 'alarmsCount',
      label: 'Events',
      style: {minWidth: 150},
    },
  ];
  const preparedVerificationStatuses =
    verifications.length > 0 ? verifications.join(',') : null;

  const {passedSortingOrder} = queryString.parse(window.location.search);

  const formatUrl = (data) => {
    const commonUrlParams = `startDate=${startDate
      .toUTC()
      .toISO()}&endDate=${endDate
      .toUTC()
      .toISO()}&verificationFilters=${preparedVerificationStatuses}`;

    if (isNewNavigationWebEnabled(currentUser)) {
      return `/ssp/alarm-events?siteId=${data.siteId}&${commonUrlParams}`;
    }
    return `/sites/${data.siteId}/alarmEventsSummary?${commonUrlParams}`;
  };

  const downloadSspTableData = useCallback(
    (pageNum, limit, sortingOrder, sortingColumn) => {
      if (verifications.length === 0) {
        return {count: 0, results: []};
      }
      return getAlarmForSitesByLabelsByDispatch({
        startDate,
        endDate,
        limit,
        offset: pageNum * limit,
        sortingColumn: snakeCase(sortingColumn),
        sortingOrder,
        verificationStatuses: verifications,
      });
    },
    [endDate, startDate, verifications],
  );

  return (
    <Grid ref={ref} item xs={12} width={10}>
      <CollapsibleTable
        name="ssp-landing-page-list"
        onLoadData={downloadSspTableData}
        onRowClick={(siteData) =>
          window.open(`/ui${formatUrl(siteData)}`, openInOneTab(currentUser))
        }
        sortable
        paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
        orderOptions={{
          initialOrderBy: passedSortingOrder ?? 'alarmsCount',
          initialOrder: 'desc',
        }}
        auxOptions={auxOptions}
        setAuxOptions={setAuxOptions}
        columns={columnsDefinition}
        rowId="siteId"
        isExpandable={false}
        stickyHeader={false}
      />
    </Grid>
  );
});

SspTableWidget.displayName = 'SSP Landing Page Table';

SspTableWidget.propTypes = {
  auxOptions: PropTypes.shape({
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  setAuxOptions: PropTypes.func,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
  verifications: PropTypes.arrayOf(
    PropTypes.oneOf([
      verificationStatuses.yes,
      verificationStatuses.no,
      verificationStatuses.unclassified,
      verificationStatuses.unverifiable,
    ]),
  ).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
};

SspTableWidget.defaultProps = {
  auxOptions: undefined,
  setAuxOptions: undefined,
};

export default memo(SspTableWidget);
