import {getHost} from './hostUtil';

const captureTargetQueryParam = (paths) => {
  const {pathname, searchParams} = new URL(window.location.href);
  const matchedPath = paths.find((path) => pathname.includes(`/${path}`));
  return matchedPath
    ? {match: matchedPath, target: searchParams.get('targetContent')}
    : null;
};

const handleEmbeddedParam = (src, updatedSrcUrl) => {
  // add &embedded=true for all admin/, app/#/ and clips2 resources
  // TODO cleanup: https://jira.mot-solutions.com/browse/ENVY-20632
  let urlToReturn = updatedSrcUrl;
  if (
    src.includes('admin/') ||
    src.includes('app/#/') ||
    src.includes('clips2')
  ) {
    urlToReturn = `${urlToReturn}${
      urlToReturn.includes('?') ? '&' : '?'
    }embedded=true`;
  }
  return urlToReturn;
};

const handleAdditionalParams = (params, updatedSrcUrl, cacheValue) => {
  let urlToReturn = updatedSrcUrl;
  if (params) {
    urlToReturn = `${urlToReturn}${
      urlToReturn.includes('?') ? '&' : '?'
    }${params}`;
  }
  return (urlToReturn = `${urlToReturn}${
    urlToReturn.includes('?') ? '&' : '?'
  }cache=${cacheValue}`);
};

const buildUrl = (src, target, match) => {
  const adminPaths = ['admin/accessList', 'admin/posList', 'admin/exportList'];
  const appPaths = ['admin/audits', 'operations/projects', 'samples/report'];

  let targetUrl = target.startsWith('/') ? target : `/${target}`;
  if (targetUrl.endsWith('/')) {
    targetUrl = targetUrl.slice(0, -1);
  }

  // handle admin paths
  if (adminPaths.some((x) => x === match)) {
    return `https://${getHost()}/admin${targetUrl}`;
  }

  // handle app/#/sample paths
  if (appPaths.some((x) => x === match)) {
    return `https://${getHost()}/app/#/samples${targetUrl}`;
  }

  // handle ops-ui paths
  if (match === 'ops/orders') {
    return `https://${getHost()}/ops${targetUrl}`;
  }

  return `${src}${targetUrl}`;
};

export const buildFrameUrl = (paths, src, params, cacheValue) => {
  let updatedSrcUrl = src;
  const {match = null, target = null} = captureTargetQueryParam(paths) || {};
  if (target) {
    updatedSrcUrl = buildUrl(src, target, match);
  }
  updatedSrcUrl = handleEmbeddedParam(src, updatedSrcUrl);
  updatedSrcUrl = handleAdditionalParams(params, updatedSrcUrl, cacheValue);
  return updatedSrcUrl;
};
