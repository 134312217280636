import React, {useRef, Fragment} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  FormControl,
  MenuItem,
  Typography,
} from '@mui/material';
import {PropTypes} from 'prop-types';
import ContactListItem from '../../../../shared/components/contactItem';
import {manageSubscription} from '../../../../api/subscribers';
import {
  formatUsername,
  hasSpecificSub,
  priorityContactsDisplayText,
  checkboxStatuses,
  subscriptionDisplayTypes,
  subscriptionEventTypes,
  getSelectablePriorities,
} from './utils';
import NotificationSubscriptionCheckbox from './NotificationSubscriptionCheckbox';

const UsersPriorityList = (props) => {
  const {
    priorityUsers,
    subIds,
    checkboxStatus,
    setCheckboxStatus,
    eventType,
    hideEmailColumn,
  } = props;

  const subRef = useRef({
    email: priorityUsers.map((u) => hasSpecificSub(u, 'email', eventType)),
    noonlight: priorityUsers.map((u) => hasSpecificSub(u, 'noonlight')),
  });
  const subCounter = subRef.current;

  return (
    <Fragment>
      <Typography variant="h6" sx={{paddingY: 2}}>
        Priority Contacts
      </Typography>
      <Table
        sx={{
          tableLayout: 'fixed',
          borderBottom: 1,
          borderColor: 'divider',
        }}
        stickyHeader
        data-cy="users-priority-table"
      >
        <TableHead>
          <TableRow
            sx={{
              '& th': {
                backgroundColor: 'primary.contrastText',
              },
            }}
          >
            <TableCell sx={{paddingX: 0, width: '100%'}}>User Name</TableCell>
            {!hideEmailColumn && (
              <TableCell sx={{width: '30%'}}>Email</TableCell>
            )}
            <TableCell sx={{width: '30%'}}>Phone</TableCell>
            <TableCell sx={{width: '100%'}}>Priority</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {priorityUsers.map((user, i) => {
            const hasSubscription = {
              email: hasSpecificSub(user, 'email'),
              noonlight: hasSpecificSub(user, 'noonlight'),
            };
            return (
              <TableRow
                key={user.uuid}
                data-cy="priority-user-subscriptions-row"
              >
                <TableCell sx={{paddingX: 0, border: 'none', width: '100%'}}>
                  <ContactListItem
                    name={formatUsername(
                      user.firstName,
                      user.lastName,
                      user.roleName,
                    )}
                    mail={user.email}
                    phone={user.phoneNumber}
                  />
                </TableCell>
                {!hideEmailColumn && (
                  <TableCell sx={{border: 'none', width: '30%'}}>
                    <NotificationSubscriptionCheckbox
                      checkboxStatus={checkboxStatus}
                      userSubscriptionType={subscriptionDisplayTypes.email}
                      userIndx={i}
                      setCheckboxStatus={setCheckboxStatus}
                      subCounter={subCounter}
                      userId={user.uuid}
                      subIds={subIds}
                      hasSubscription={hasSubscription}
                      priorityUsers={priorityUsers}
                    />
                  </TableCell>
                )}
                <TableCell sx={{border: 'none', width: '30%'}}>
                  <NotificationSubscriptionCheckbox
                    checkboxStatus={checkboxStatus}
                    userSubscriptionType={subscriptionDisplayTypes.noonlight}
                    userIndx={i}
                    setCheckboxStatus={setCheckboxStatus}
                    subCounter={subCounter}
                    userId={user.uuid}
                    subIds={subIds}
                    hasSubscription={hasSubscription}
                    priorityUsers={priorityUsers}
                    checkboxDisabled={!user.phoneNumber}
                  />
                </TableCell>
                <TableCell sx={{border: 'none', width: '100%'}}>
                  <FormControl variant="outlined" sx={{width: '100%'}}>
                    <Select
                      data-cy="priority-select"
                      labelId="priority-select-label"
                      disabled={checkboxStatus === checkboxStatuses.Loading}
                      value={
                        user.subscriptions.find(
                          (sub) =>
                            sub.type === 'noonlight' &&
                            sub.eventType === eventType,
                        ).priority
                      }
                      onChange={(event) => {
                        setCheckboxStatus(checkboxStatuses.Loading);
                        return manageSubscription(
                          user.uuid,
                          subIds.noonlight,
                          true,
                          event.target.value,
                        )
                          .then(() => {
                            setCheckboxStatus(checkboxStatuses.Success);
                          })
                          .catch(() => {
                            setCheckboxStatus(checkboxStatuses.Error);
                          });
                      }}
                      size="small"
                    >
                      {getSelectablePriorities(priorityUsers, eventType).map(
                        (priority) => (
                          <MenuItem
                            data-cy={`priority-option-${priority}`}
                            key={`${priority}`}
                            value={priority}
                          >
                            {priorityContactsDisplayText(priority)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

UsersPriorityList.propTypes = {
  priorityUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      uuid: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      roleName: PropTypes.string,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          eventType: PropTypes.string,
          subscriptionId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  subIds: PropTypes.shape({
    email: PropTypes.string,
    noonlight: PropTypes.string.isRequired,
  }).isRequired,
  eventType: PropTypes.oneOf(Object.values(subscriptionEventTypes)).isRequired,
  hideEmailColumn: PropTypes.bool.isRequired,
  checkboxStatus: PropTypes.string.isRequired,
  setCheckboxStatus: PropTypes.func.isRequired,
};

export default UsersPriorityList;
