import isArray from 'lodash/isArray';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import queryString from 'query-string';

import {MINIMUM_SITES_FOR_CATEGORY_DISPLAY} from '../constants';
import {flatten} from './arrays';
import {setLocalStorageItem} from './storage';

export const toCategory = (category, children, numSites) => {
  const {id, name, parentId, retailStoreCode} = category;

  return {
    id,
    name,
    parentId,
    retailStoreCode,
    children,
    numSites,
    type: 'category',
  };
};

export const mergeSiteCat = (category) => {
  const cat = isArray(category) ? head(category) : category;
  if (isEmpty(cat)) {
    return {numSites: 0};
  }

  const addSiteType = (s) => ({
    ...s,
    type: 'site',
  });

  const innerFlatten = (c) => {
    const merged = c.categories.map(innerFlatten);
    const sites = c.sites.map(addSiteType);
    const children = sites.concat(merged);
    const numSites =
      sites.length + merged.map((t) => t.numSites).reduce((a, b) => a + b, 0);
    return toCategory(c, children, numSites);
  };

  return innerFlatten(cat);
};

export const findCategory = (category, categoryId) => {
  const inner = (child) => {
    if (child.type === 'category' && child.id === categoryId) {
      return child;
    }
    const possibleFind = child.children && child.children.map((c) => inner(c));
    return possibleFind && possibleFind.find((f) => typeof f !== 'undefined');
  };

  return categoryId ? inner(category) || category : category;
};

export const getChildSites = (cat) =>
  cat.children.filter((c) => c.type === 'site');

const getChildCategories = (cat) =>
  cat.children.filter((c) => c.type === 'category');

export const flattenSites = (category, schedules) => {
  const collectSites = (sites, nextCategory) =>
    sites.concat(
      getChildCategories(nextCategory).reduce(
        collectSites,
        getChildSites(nextCategory),
      ),
    );

  const sites = getChildCategories(category).reduce(
    collectSites,
    getChildSites(category),
  );

  if (schedules) {
    const schedulesMap = schedules.reduce((acc, curr) => {
      if (curr.siteId) {
        acc.set(curr.siteId, curr.name);
      }
      return acc;
    }, new Map());
    return sites.map((site) => ({
      ...site,
      scheduleName: schedulesMap.get(site.id) ?? undefined,
    }));
  }

  return sites;
};

export const flattenCategories = (category) => {
  const collectCategories = (sites, nextCategory) =>
    sites.concat(
      getChildCategories(nextCategory).reduce(
        collectCategories,
        getChildCategories(nextCategory),
      ),
    );

  return getChildCategories(category).reduce(
    collectCategories,
    getChildCategories(category),
  );
};

const pruneCategory = (category) => {
  const categoryReducer = (categories, nextCat) => {
    const childCategories = getChildCategories(nextCat).reduce(
      categoryReducer,
      [],
    );
    const children = getChildSites(nextCat).concat(childCategories);
    if (nextCat.numSites > 0) categories.push({...nextCat, children});
    return categories;
  };

  return categoryReducer([], category)[0] || {numSites: 0};
};

export const findLowestRoot = (category) => {
  if (getChildSites(category).length > 0) return category;
  const populatedCategories = getChildCategories(category).filter(
    (c) => c.numSites > 0,
  );
  if (populatedCategories.length > 1) return category;
  return findLowestRoot(populatedCategories[0]);
};

export const getRootCategory = (
  mergedCategory,
  minimumSiteCount = MINIMUM_SITES_FOR_CATEGORY_DISPLAY,
) => {
  if (!mergedCategory.numSites) return {...mergedCategory, children: []};
  const category = pruneCategory(mergedCategory);
  if (category.numSites <= minimumSiteCount)
    category.children = flattenSites(category);
  return findLowestRoot(category);
};

export const dedupSites = (sites) => {
  const dedupedSites = [];
  sites.forEach((item) => {
    if (dedupedSites.findIndex((dupItem) => dupItem.id === item.id) === -1) {
      dedupedSites.push(item);
    }
  });
  return dedupedSites;
};

export const getSiteAddress = (site) => {
  if (site.type !== 'site') return undefined;
  const street = [site.street1, site.street2]
    .reduce((strArray, str) => {
      if (str) strArray.push(str);
      return strArray;
    }, [])
    .join(' ');
  const locationInfo = street ? [street] : [];
  if (site.city) {
    locationInfo.push(site.city);
  }
  if (site.region && site.city !== site.region) {
    locationInfo.push(site.region);
  }
  return locationInfo.join(', ') || '--';
};

export const flattenSitesListForSearch = (sites, sitePath) => {
  if (!sites) return [];
  const sitesToFlatten = sites.children ? sites.children : sites;
  return dedupSites(
    flatten(
      sitesToFlatten.map((item) =>
        item.children && item.children.length
          ? flattenSitesListForSearch(
              item.children,
              `${sitePath} > ${item.name}`,
            ).concat({...item, sitePath})
          : {...item, sitePath, siteAddress: getSiteAddress(item)},
      ),
    ),
  );
};

export const videoPath = (
  siteId,
  cameras,
  startDate,
  isNewNavigationWebEnabled,
) => {
  let path = `/sites/${siteId}/video?cameras=${cameras
    .map((c) => {
      if (c == null) {
        return undefined;
      }
      return c.id;
    })
    .join(',')}`;
  if (startDate) {
    path = path.concat(`&startTime=${startDate.toISO()}`);
  }

  return isNewNavigationWebEnabled === true ? `/home${path}` : path;
};

export const monitorPath = (cameras) =>
  `/ui/live-monitor/view?cameras=${cameras
    .map((c) => {
      if (c == null) {
        return undefined;
      }
      return c.id;
    })
    .join(',')}`;

const retrieveViews = () => {
  const recentViews = window.localStorage.getItem('recentViews');
  return recentViews ? JSON.parse(recentViews) : {};
};

export const retrieveRecentViews = (userId) => {
  const views = retrieveViews();
  return views[userId] || [];
};

export const addToRecentViews = (userId, cameraArray) => {
  const {siteId} = cameraArray[0];
  const cameras = cameraArray.map((camera) => ({
    id: camera.id,
    name: camera.name,
  }));
  const recentViews = retrieveViews();
  const userViews = recentViews[userId] || [];
  userViews.unshift({siteId, cameras});
  const uniqViews = uniqWith(userViews, isEqual);
  const updatedViews = {...recentViews, [userId]: uniqViews.slice(0, 5)};
  setLocalStorageItem('recentViews', updatedViews);
};

export const buildSiteListFromCameras = (cameras) => {
  return uniqBy(
    cameras.reduce((sites, {siteId: id, siteName: name}) => {
      sites.push({id, name});
      return sites;
    }, []),
    'id',
  );
};

export const buildSiteSubpath = (location, siteId, paramBlacklist = []) => {
  const pathSplit = location.pathname.split('/');
  const siteIdIndex = pathSplit.findIndex((p) => p === siteId);
  const subpath = pathSplit[siteIdIndex + 1];

  const queryParams = omit(queryString.parse(location.search), paramBlacklist);
  const queryParamsString = queryString.stringify(queryParams);

  let pathString = subpath || '';

  if (queryParamsString) {
    pathString = `${pathString}?${queryParamsString}`;
  }

  return pathString;
};

/**
 * transforms site or category object
 * @param {Object} node - site or category
 * @param {String[]} cloudArchiveEnabledSites - an array of site IDs with cloud archive enabled
 * @returns {Object} - transformed site or category node object
 */
export const transformNode = (node, cloudArchiveEnabledSites = []) => {
  if (node.type === 'category') {
    const {sitesCount: numSites, ...rest} = node;
    return {...rest, numSites};
  }
  if (node.type === 'site') {
    return {
      ...node,
      cloudArchiveEnabled: cloudArchiveEnabledSites.includes(node.id),
      sitePath: (node.sitePath || []).join(' > '),
    };
  }
  return node;
};

export default {mergeSiteCat};
