import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {useApi} from '../../../../shared/hooks';
import {
  alarmDispatches,
  alarmLabels,
} from '../../../../pages/sites/details/alarmEventsSummary/utils';
import {getAlarmStatisticsForSites} from '../../../../api/alarms';
import SspLateDepartureWidget from '../../../../pages/smartSiteProtection/landingPage/lateDepartureWidget';
import {openInOneTab} from '../../utils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const currentTimestamp = DateTime.local().toUTC();
const weekAgoTimestamp = DateTime.local()
  .minus({
    day: 7,
  })
  .toUTC();

const LateDepartureWidget = ({widgetConfig, currentUser}) => {
  const [alarms, loading, , getAlarms] = useApi(getAlarmStatisticsForSites);
  useEffect(
    () => {
      getAlarms(
        weekAgoTimestamp,
        currentTimestamp,
        [alarmDispatches.serviceContacted, alarmDispatches.canceled],
        [alarmLabels.eventsOnly.name],
        [alarmLabels.lateDeparture.name],
      );
    },
    [getAlarms],
  );

  const data = {
    lateDepartures: alarms?.labels?.lateDeparture ?? 0,
    otherNoThreats: (alarms?.no ?? 0) - (alarms?.labels?.lateDeparture ?? 0),
  };
  const handleClick = () => {
    window.open(
      `/ui/ssp?passedSortingOrder=lateDeparture`,
      openInOneTab(currentUser),
    );
  };

  return (
    <SspLateDepartureWidget
      id={widgetConfig.widgetReference}
      onActionClick={handleClick}
      loading={loading}
      data={data}
      buttonIcon={<OpenInNewIcon />}
      buttonLabel="View More Details"
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(LateDepartureWidget);
