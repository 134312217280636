import React, {useRef, useMemo, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
} from '@mui/material';
import camelCase from 'lodash/camelCase';
import AddIcon from '@mui/icons-material/Add';
import {PropTypes} from 'prop-types';
import ContactListItem from '../../../../shared/components/contactItem';
import AddPhoneNumberPopover from './AddPhoneNumberPopover';
import {
  formatUsername,
  subscriptionEventTypes,
  hasSpecificSub,
  subscriptionDisplayTypes,
  checkboxStatuses,
} from './utils';
import Spinner from '../../../../shared/components/spinner';
import UsersPriorityList from './UsersPriorityList';
import NotificationSubscriptionCheckbox from './NotificationSubscriptionCheckbox';
import CallbackFilterField from '../../../../shared/components/callbackFilterField';

const UsersNotificationList = (props) => {
  const {
    standardUsers,
    priorityUsers,
    onSearchUsers,
    searchValue,
    dataSubscriptions,
    checkboxStatus,
    setCheckboxStatus,
    eventType,
    isEM,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const open = Boolean(anchorEl);

  const handleOpenPhonePopover = (event, userId) => {
    setSelectedUser(userId);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePhonePopover = () => setAnchorEl(null);

  const subIds = useMemo(
    () =>
      dataSubscriptions.siteSubscriptions.reduce((acc, current) => {
        const {uuid, type} = current;
        if (type == null) return acc;

        acc[camelCase(type)] = uuid;
        return acc;
      }, {}),
    [dataSubscriptions],
  );

  const subRef = useRef({
    email: standardUsers?.map((u) => hasSpecificSub(u, 'email', eventType)),
    noonlight: standardUsers?.map((u) => hasSpecificSub(u, 'noonlight')),
    pushNotification: standardUsers?.map((u) =>
      hasSpecificSub(u, 'push_notification'),
    ),
  });
  const subCounter = subRef.current;

  const isNotDmp = eventType !== subscriptionEventTypes.panel;
  const hideEmailColumn =
    eventType === subscriptionEventTypes.panic ||
    (isEM && eventType === subscriptionEventTypes.video);

  const getCheckboxComponents = (user, i, hasSubscription) => {
    const checkboxTypes = {
      panel: [{type: subscriptionDisplayTypes.email, restProps: {}}],
      panic: [
        {
          type: subscriptionDisplayTypes.noonlight,
          restProps: {
            priorityUsers,
            checkboxDisabled: !user.phoneNumber || priorityUsers.length >= 3,
          },
        },
      ],
      EM: [
        ...(hideEmailColumn
          ? []
          : [{type: subscriptionDisplayTypes.email, restProps: {}}]),
        {
          type: subscriptionDisplayTypes.noonlight,
          restProps: {
            priorityUsers,
            checkboxDisabled: !user.phoneNumber || priorityUsers.length >= 3,
          },
        },
      ],
      selfVerification: [
        {type: subscriptionDisplayTypes.email, restProps: {}},
        {type: subscriptionDisplayTypes.push_notification, restProps: {}},
      ],
    };

    let subType;
    switch (eventType) {
      case subscriptionEventTypes.panel:
        subType = 'panel';
        break;
      case subscriptionEventTypes.video:
        subType = isEM ? 'EM' : 'selfVerification';
        break;
      case subscriptionEventTypes.panic:
        subType = 'panic';
        break;
      default:
        break;
    }

    return checkboxTypes[subType].map(({type, restProps}) => (
      <TableCell key={type} sx={{borderBottom: 'none', width: '30%'}}>
        <NotificationSubscriptionCheckbox
          checkboxStatus={checkboxStatus}
          userSubscriptionType={type}
          userIndx={i}
          setCheckboxStatus={setCheckboxStatus}
          subCounter={subCounter}
          userId={user.id}
          subIds={subIds}
          hasSubscription={hasSubscription}
          {...restProps}
        />
      </TableCell>
    ));
  };

  return (
    <Box>
      {isEM && priorityUsers.length !== 0 && (
        <UsersPriorityList
          priorityUsers={priorityUsers}
          subIds={subIds}
          checkboxStatus={checkboxStatus}
          setCheckboxStatus={setCheckboxStatus}
          eventType={eventType}
          hideEmailColumn={hideEmailColumn}
          id="users-priority-list"
        />
      )}
      <Typography variant="h6" sx={{paddingTop: 2}}>
        Users
      </Typography>
      <CallbackFilterField
        filterValue={searchValue.current}
        onFilter={(query) => onSearchUsers(query)}
        isDebounced
        data-cy="user-search-field"
        sx={{width: '100%', marginY: 0}}
      />
      {standardUsers ? (
        <Table
          sx={{tableLayout: 'fixed'}}
          stickyHeader
          data-cy="users-notification-list"
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  backgroundColor: 'primary.contrastText',
                },
                width: '100%',
              }}
            >
              <TableCell sx={{paddingX: 0, width: '100%'}}>User Name</TableCell>
              {!hideEmailColumn && (
                <TableCell sx={{width: '30%'}}>Email</TableCell>
              )}
              <TableCell sx={{width: '30%'}}>
                {isNotDmp && (isEM ? 'Phone' : 'Push')}
              </TableCell>
              <TableCell sx={{width: '100%'}} />
            </TableRow>
          </TableHead>
          <TableBody>
            {standardUsers?.map((user, i) => {
              const hasSubscription = {
                email: hasSpecificSub(user, 'email'),
                noonlight: hasSpecificSub(user, 'noonlight'),
                pushNotification: hasSpecificSub(user, 'push_notification'),
              };
              return (
                <TableRow key={user.id} data-cy="user-subscriptions-row">
                  <TableCell
                    sx={{paddingX: 0, borderBottom: 'none', width: '100%'}}
                  >
                    <ContactListItem
                      name={formatUsername(
                        user.firstName,
                        user.lastName,
                        user.roleName,
                      )}
                      mail={user.email}
                      phone={user.phoneNumber}
                    />
                  </TableCell>
                  {getCheckboxComponents(user, i, hasSubscription)}
                  <TableCell sx={{borderBottom: 'none', width: '100%'}}>
                    {!user.phoneNumber && (isEM && isNotDmp) && (
                      <Button
                        data-cy="add-phone-number"
                        variant="outlined"
                        onClick={(event) =>
                          handleOpenPhonePopover(event, user.id)
                        }
                        endIcon={<AddIcon />}
                      >
                        Add number
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <AddPhoneNumberPopover
            open={open}
            anchorEl={anchorEl}
            handleClosePhonePopover={handleClosePhonePopover}
            userId={selectedUser}
          />
        </Table>
      ) : (
        <Box
          sx={{
            paddingTop: 2.5,
          }}
        >
          <Spinner size={50} color="primary" />
        </Box>
      )}
    </Box>
  );
};

UsersNotificationList.propTypes = {
  standardUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      roleName: PropTypes.string,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          eventType: PropTypes.string,
          subscriptionId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ),
  priorityUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      uuid: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      roleName: PropTypes.string,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          eventType: PropTypes.string,
          subscriptionId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  dataSubscriptions: PropTypes.shape({
    noonlightEnabled: PropTypes.bool,
    siteSubscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        eventType: PropTypes.string,
        siteId: PropTypes.string,
        siteName: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  eventType: PropTypes.oneOf(Object.values(subscriptionEventTypes)).isRequired,
  checkboxStatus: PropTypes.oneOf(Object.values(checkboxStatuses)).isRequired,
  setCheckboxStatus: PropTypes.func.isRequired,
  isEM: PropTypes.bool.isRequired,
  searchValue: PropTypes.shape({current: PropTypes.string}),
};

UsersNotificationList.defaultProps = {
  standardUsers: undefined,
  searchValue: {current: ''},
};
export default UsersNotificationList;
