export const getGraphSettings = (theme) => {
  const graphSettings = {
    theme,
    title: 'Vehicle Journey Times by Location',
    subtitle: 'Minimum, Maximum, and Average Journey Times Across Locations',
    yAxisTitle: 'Dwell Times (min)',
    columnColor: theme.palette.primary.main,
    hoverColor: theme.palette.primary.light,
    widgetName: 'Value-range',
    chartType: 'columnrange',
  };

  return graphSettings;
};

export const seriesFromData = (config, data) => {
  const formattedSeries = [];

  config.forEach((series) => {
    const formattedData = data.map((element) => {
      const extractedFields = {};
      Object.entries(series.dataFormat).forEach(([key, value]) => {
        if (Object.hasOwn(element, value)) {
          extractedFields[key] = element[value];
        }
      });
      return extractedFields;
    });
    formattedSeries.push({
      ...series,
      data: formattedData,
    });
  });

  return formattedSeries;
};

export const formatSecondsToMinutes = (seconds) => {
  const cleanSeconds = Number(seconds).toFixed(0);
  const minutes = Math.floor(cleanSeconds / 60);
  const remainingSeconds = cleanSeconds % 60;
  const formattedValue = `${String(minutes).padStart(2, '0')}:${String(
    remainingSeconds,
  ).padStart(2, '0')}`;
  return seconds > 60 ? formattedValue : seconds;
};

export const seriesConfigsTop = (theme) => {
  return [
    {
      name: 'Range by Site',
      type: 'columnrange',
      states: {hover: {enabled: false}},
      dataFormat: {
        x: 'id',
        name: 'retailStoreName',
        low: 'journeyLow',
        high: 'journeyHigh',
        avg: 'journeyAvg',
        drilldown: 'drilldown',
        url: 'reportUrl',
        storeCode: 'storeCode',
      },
      tooltip: {
        pointFormatter() {
          return `Range: ${formatSecondsToMinutes(this.low.toFixed(0))} ${
            this.low > 60 ? 'm' : 's'
          } - ${formatSecondsToMinutes(this.high.toFixed(0))} ${
            this.high > 60 ? 'm' : 's'
          }`;
        },
      },
    },
    {
      name: 'Avg-value',
      type: 'scatter',
      marker: {
        symbol: 'circle',
        radius: 4,
        lineWidth: 1,
        lineColor: theme.palette.common.white,
        fillColor: theme.palette.graphColor[300],
      },
      tooltip: {
        pointFormatter() {
          return `Avg: ${formatSecondsToMinutes(this.y.toFixed(0))} ${
            this.y > 60 ? 'm' : 's'
          }`;
        },
      },
      dataFormat: {
        x: 'id',
        y: 'journeyAvg',
      },
    },
  ];
};

export const seriesConfigsDrilldown = () => {
  return [
    {
      name: 'Value-range',
      type: 'columnrange',
      states: {hover: {enabled: false}},
      dataFormat: {
        x: 'id',
        name: 'day',
        low: 'journeyLow',
        high: 'journeyHigh',
        url: 'reportUrl',
        storeCode: 'storeCode',
      },
    },
  ];
};
