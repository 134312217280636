import allowed, {
  BI_REPORT_2,
  VIEW_PROJECTS,
  hasCapability,
} from '../../util/allowed';

export const incidentsHosts = Object.freeze({
  dev: 'https://tsappdev1.envysion.com',
  test: 'https://tsapptest1.envysion.com',
  stage: 'https://tsapp.envysion.com',
  uat: 'https://tsapp.envysion.com',
  prod: 'https://tsapp.envysion.com',
});

export const getUserLandingPage = (user) => {
  const {settings} = user;

  const dashboardLandingPage = '/app/#/dashboard';
  const newDashboardLandingPage = '/ui/dashboard';
  const videoLandingPage = '/ui/sites';

  const userHasReportOrViewProjectCapability = allowed(user, [
    BI_REPORT_2,
    VIEW_PROJECTS,
  ]);
  if (settings.landingPage === 'Default') {
    if (settings.newDashboard) {
      return newDashboardLandingPage;
    }
    if (userHasReportOrViewProjectCapability) {
      return dashboardLandingPage;
    }
  }

  return videoLandingPage;
};

export const hasRequiredCapabilityWithMatchingPreference = (item, user) => {
  const hasRequiredCapabilities =
    item.capabilities.length === 0 ||
    item.capabilities.some((capability) => hasCapability(user, capability));

  const hasMatchingPreferences =
    item.preferences.length === 0 ||
    item.preferences.some((preference) => {
      const [[name, value]] = Object.entries(preference);
      return user.settings[name] === value;
    });

  return hasRequiredCapabilities && hasMatchingPreferences;
};

export const hasAllMenuCapabilities = (user, menuItems) =>
  allowed(
    user,
    menuItems
      .map((adminRoute) => adminRoute.capabilities)
      .reduce((acc, val) => {
        if (val.length === 0) return [];
        return acc.concat(val);
      }, []),
  );
