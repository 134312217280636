import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import ZoomableChart from '../../../../shared/components/highcharts/zoomableChart';
import {getDriveThruInsights} from '../../../../api/reports';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import {extraOptions} from './driveThruInsightsUtils';

const DriveThruInsightsZoomable = ({currentUser, widgetConfig, filtering}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;
  const params = {
    filters: filtering?.filters,
    reportLookBackDays,
    currentUserDate,
  };

  const subtitle = () => {
    const addMessage =
      document.ontouchstart === undefined
        ? '<br/> Click and drag in the plot area to zoom in'
        : ' <br/> Pinch the chart to zoom in';
    return widgetConfig.description
      ? widgetConfig.description + addMessage
      : `Drive Thru Occupancy compared to Journey times and Sales Dollars.${addMessage}`;
  };
  const theme = useTheme();
  const seriesConfigs = [
    {
      name: 'Vehicle Count',
      type: 'area',
      yAxis: 0,
      opacity: 1,
      states: {hover: {enabled: false}},
      dataFormat: {
        x: 'calendarId',
        y: 'journeyCount',
        url: 'reportUrl',
        drilldown: 'drilldown',
      },
    },
    {
      name: 'Avg Journey Time',
      type: 'line',
      yAxis: 1,
      opacity: 1,
      color: theme.palette.accent.main,
      states: {hover: {enabled: false}},
      dataFormat: {
        name: 'id',
        x: 'calendarId',
        y: 'avgJourneyTimeS',
        url: 'reportUrl',
        drilldown: 'drilldown',
      },
    },
  ];

  const seriesFromData = (config, data) => {
    if (data.length === 0) {
      return [];
    }
    // Format calendarid into format Date for highcharts
    data.map((element) => {
      const date = DateTime.fromFormat(
        String(element.calendarId),
        'yyyyMMddHH',
        {zone: 'utc'},
      ).toJSDate();
      // eslint-disable-next-line no-param-reassign
      element.calendarId = date;
      return element;
    });

    const formattedSeries = [];
    config.map((series) =>
      formattedSeries.push({
        ...series,
        data: data.map((element) => {
          const extractedFields = {};

          Object.entries(series.dataFormat).map(([key, value]) =>
            Object.prototype.hasOwnProperty.call(element, value)
              ? (extractedFields[key] = element[value])
              : null,
          );
          return extractedFields;
        }),
      }),
    );
    return formattedSeries;
  };

  const loadData = async () => {
    let count = 5000;
    let offset = 0;
    const limit = 500;
    const getDataCall = getDriveThruInsights;

    let results = [];
    // 2160 is the 90 day max we want to return. 90days x 24hrs = 2160 data points
    while (count > offset && offset < 2160) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await getDataCall({
          filters: params.filters,
          reportLookBackDays,
          currentUserDate,
          limit,
          offset,
        });
        count = response.count;
        results = results.concat(response.results);
        offset += response.results.length;
      } catch {
        return 'Error Loading Data';
      }
    }

    return seriesFromData(seriesConfigs, results);
  };

  const getContent = () => {
    return (
      <ZoomableChart
        id="highcharts-widget-drive-thru-insights"
        onLoad={loadData}
        onDrilldown={() => {
          return [];
        }}
        params={params}
        graphSettings={{
          theme,
          title: widgetConfig.name || 'Drive Thru Insights',
          hoverColor: theme.palette.primary.light,
          widgetName: 'drive-thru-insights-zoomable',
          subtitle: subtitle(),
          chartType: 'area',
        }}
        extraOptions={() => extraOptions(theme)}
      />
    );
  };
  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(DriveThruInsightsZoomable);
